// --------------------------------------------------
// Images
// --------------------------------------------------
document.addEventListener("DOMContentLoaded", function () {
  document.querySelectorAll(".lazy-image").forEach((img) => {
    if (img.complete) {
      img.classList.add("loaded");
    } else {
      img.addEventListener("load", () => img.classList.add("loaded"));
    }
  });
});

// --------------------------------------------------
// 🐌 General
// --------------------------------------------------

import Splide from "@splidejs/splide";

// --------------------------------------------------
// Years scroll
// --------------------------------------------------

document.addEventListener("DOMContentLoaded", function () {
  const observedDivs = document.querySelectorAll("[id^='year-']");
  const targetDivs = document.querySelectorAll("[data-year]");

  let lastActiveId = null; // Keep track of the last active div

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const targetId = entry.target.id;
          const targetDiv = document.querySelector(`[data-year='${targetId}']`);

          if (targetDiv && targetId !== lastActiveId) {
            // Remove .active from all target divs
            targetDivs.forEach((div) => div.classList.remove("highlighted"));
            // Add .active to the newly visible div
            targetDiv.classList.add("highlighted");

            lastActiveId = targetId; // Update last active ID
          }
        }
      });
    },
    {
      root: null, // Viewport as the root
      rootMargin: "-25% 0px -75% 0px", // Triggers when entering the top 25%
      threshold: 0, // Fire as soon as it enters
    }
  );

  // Observe each div in the scrollable list
  observedDivs.forEach((div) => observer.observe(div));
});

// --------------------------------------------------
// 🎠 Carousels
// --------------------------------------------------
let newsCarousel = document.querySelector(".news-carousel");

if (newsCarousel) {
  let newsSplide = new Splide(newsCarousel, {
    type: "fade",
    rewind: true,
    speed: 0,
    pauseOnHover: true,
    autoplay: true,
    interval: 3000,
    arrows: false,
    pagination: false,
    perPage: 1,
    perMove: 1,
    focus: "center",
    width: "100%",
    autoHeight: true,
    updateOnMove: true,
  });

  newsSplide.mount();

  // Auto height
  const autoHeight = () => {
    let activeSlide = newsSplide.Components.Elements.slides[newsSplide.index];
    let splideList = newsCarousel.querySelector(".splide__list");
    if (splideList && activeSlide) {
      splideList.style.height = activeSlide.offsetHeight + "px";
    }
  };

  let counterStatuses = document.querySelectorAll(".carousel-counter__status");

  const updateCounter = () => {
    counterStatuses.forEach((counterStatus) => {
      counterStatus.innerHTML = newsSplide.index + 1;
    });
  };

  autoHeight();
  newsSplide.on("move", () => {
    autoHeight();
    updateCounter();
  });
}

// --------------------------------------------------
// Description text read more buttons
// --------------------------------------------------

const descriptionWrapper = document.querySelector(".description-wrapper");

if (descriptionWrapper) {
  const description = document.querySelector(".description");
  const readMoreBtn = document.querySelector("#read-more");

  // Check if content overflows and show button if necessary
  if (description.scrollHeight > description.clientHeight) {
    readMoreBtn.style.display = "block"; // Show the "Read more" button
  } else {
    readMoreBtn.style.display = "none"; // Hide the "Read more" button
  }

  readMoreBtn.addEventListener("click", function () {
    const isExpanded = descriptionWrapper.classList.toggle("expanded");

    readMoreBtn.textContent = isExpanded ? "Read less" : "Read more";
    readMoreBtn.setAttribute("aria-expanded", isExpanded);
  });
}

// --------------------------------------------------
// Modals
// --------------------------------------------------

// Select all modal toggle buttons
const modalButtons = document.querySelectorAll("[data-id]");

if (modalButtons) {
  modalButtons.forEach((button) => {
    button.addEventListener("click", function (event) {
      event.stopPropagation(); // Prevent click from bubbling to document
      const modalId = this.getAttribute("data-id");
      const modal = document.getElementById(modalId);
      if (modal) {
        modal.classList.add("active");
      }
    });
  });

  // Select all modal close buttons
  const closeButtons = document.querySelectorAll(
    "#about-modal-close, #texts-modal-close, #contact-modal-close, #news-modal-close"
  );

  closeButtons.forEach((button) => {
    button.addEventListener("click", function (event) {
      event.stopPropagation(); // Prevent click from bubbling to document
      const modal = this.closest(".active");
      const newsModal = this.closest(".active-news");
      if (modal) {
        modal.classList.remove("active");
      }
      if (newsModal) {
        newsModal.classList.remove("active-news");
      }
    });
  });

  // Close modals when clicking outside them
  document.addEventListener("click", function (event) {
    const activeModals = document.querySelectorAll(".active");
    activeModals.forEach((modal) => {
      if (!modal.contains(event.target)) {
        modal.classList.remove("active");
      }
    });
  });
}

// --------------------------------------------------
// Back button
// --------------------------------------------------

document.addEventListener("DOMContentLoaded", function () {
  if (document.referrer.includes("elizabethwilling.com")) {
    const anchor = document.querySelector(".work-close");

    if (anchor) {
      const button = document.createElement("button");
      button.textContent = anchor.textContent || "Close (X)";
      button.className = anchor.className;
      button.addEventListener("click", function () {
        window.history.back();
      });

      anchor.replaceWith(button);
    }
  }
});
